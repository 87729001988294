import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import { authReducer } from "../app/modules/Acceso/handlers/reducer";
import { carritoReducer } from "../app/modules/Carrito/handlers/reducer";
const reducer = combineReducers({
  auth: authReducer,
  router: routerReducer,
  form: formReducer,
  carrito: carritoReducer,
});

export default reducer;
