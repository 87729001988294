import "./bootstrap";

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, createStore } from "redux";
//import registerServiceWorker from './registerServiceWorker';

import App from "./app/App";
import Saga from "./saga";
import reducer from "./reducer";

import "bootstrap/dist/css/bootstrap.css";
import "./css/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-owl-carousel2/src/owl.carousel.css"; //Allows for server-side rendering.
import "react-owl-carousel2/src/owl.theme.default.css"; //Allows for server-side rendering.

import "./css/ui.css";
import "./css/responsive.css";
import "./css/custom.css";
import "./css/mobile.css";

import jQuery from "jquery";
import "jquery-mousewheel/jquery.mousewheel";
window.$ = window.jQuery = jQuery;
// fn(window.$);

// create middlewares
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware);

// create store
const store = createStore(reducer, middleware);

// run saga middleware
sagaMiddleware.run(Saga);

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);
//registerServiceWorker();

if (module.hot) {
  module.hot.accept();
}
