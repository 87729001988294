import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  SET_DIRECCIONES,
  SET_ADICIONALES,
  AUTH_SET_DATA,
} from "./constants";
import { loginApi } from "./api";
import { LOCATION_CHANGE, push } from "react-router-redux";

function* authorize(action) {
  console.log(action);
  const Api = loginApi();

  try {
    const { token } = yield call(
      Api.login,
      action.payload.email,
      action.payload.password
    );

    const user = yield call(Api.me, token);

    yield put({ type: AUTH_SUCCESS, payload: token, user });
    localStorage.setItem("socialProof.token", token);
    localStorage.setItem("socialProof.user", JSON.stringify(user));
    // action.callbackSuccess();

    let { carrito = [] } = user;

    if ((carrito || []).length < 1) {
      yield put(push("/"));
    } else {
      yield put(push("/carrito"));
    }
  } catch (error) {
    error = error.error || error;
    yield put({ type: AUTH_FAILURE, payload: error });
    localStorage.removeItem("token");
    // action.callbackError(errors.message);
  }
}

function* logout(action) {
  localStorage.removeItem("socialProof.token");
  localStorage.removeItem("socialProof.user");
  yield put({ type: AUTH_LOGOUT });
  // action.callbackSuccess();
}

function* setDirecciones(action) {
  const token = yield select((state) => state.auth.token);
  const Api = loginApi();

  try {
    yield call(Api.direcciones, token, action.payload.direcciones);
  } catch (error) {
    console.log({ error });
  }
}

function* setAdicionales(action) {
  const token = yield select((state) => state.auth.token);
  const Api = loginApi();

  try {
    yield call(Api.adicionales, token, action.payload.adicionales);
  } catch (error) {
    console.log({ error });
  }
}

function* registrar(action) {
  console.log(action);
  const Api = loginApi();

  let { usuario, direccion, facturacion } = action.payload;
  let { punto = {}, sucursal = {} } = direccion;

  if (!!sucursal.nombre) {
    direccion.sucursal = sucursal.nombre;
  }

  if (!!punto.lat) {
    direccion.coordinates = direccion.lat + ", " + direccion.lng;
  }

  try {
    const { token } = yield call(Api.registrar, {
      usuario,
      direccion,
      facturacion,
    });

    // action.callbackSuccess();
    yield put(push("/ingresar"));
  } catch (error) {
    console.log(error);
    error = error.error || error;
    yield put({
      type: "REGISTRAR" + "_FAILURE",
      payload: error,
    });
    // action.callbackError(errors.message);
  }
}

function* authLoadData() {
  const Api = loginApi();
  try {
    let banner = yield call(Api.getData, "banner");
    let categorias = yield call(Api.getData, "categorias");
    let categorias_destacadas = yield call(
      Api.getData,
      "categorias_destacadas"
    );
    let productos = yield call(Api.getData, "productos");
    let productos_destacadas = yield call(Api.getData, "productos_destacadas");
    let sucursales = yield call(Api.getData, "sucursales");
    let menu = yield call(Api.getData, "menu");

    yield put({
      type: AUTH_SET_DATA,
      payload: {
        banner,
        categorias,
        categorias_destacadas,
        productos,
        productos_destacadas,
        sucursales,
        menu,
      },
    });
  } catch (error) {}
}

function* accesoSaga() {
  yield takeLatest(LOCATION_CHANGE, authLoadData);
  yield takeLatest(SET_DIRECCIONES, setDirecciones);
  yield takeLatest(SET_ADICIONALES, setAdicionales);
  yield takeLatest(AUTH_REQUEST, authorize);
  yield takeLatest("REGISTRAR", registrar);
  yield takeLatest(AUTH_LOGOUT_REQUEST, logout);
}

export default accesoSaga;
