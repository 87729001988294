import React, { useEffect } from "react";
// import { hot } from "react-hot-loader/root";

import { Router } from "./router";

const App = (props) => {
  // Place this line in your function
  const { history } = props;

  // useEffect(() => {
  //   history.listen((location) => {
  //     if (!!window.gtag) {
  //       window.gtag("event", "page_view", {
  //         page_location: location.pathname,
  //       });
  //     }
  //   });
  // }, []);

  return <Router history={history} />;
};

export default App;
